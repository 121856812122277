import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';


const Loading = () => {
    // return <div className='loading'><Spin /></div>
    return <>
        <div className='loading-page'>
            <svg id="logo" width="402" height="111" viewBox="0 0 402 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M52.25 83.5625L75.375 101.875L67.25 109.375L44.5 91.3125L52.25 83.5625ZM75.0625 45.625V51.375C75.0625 58.2083 74.2083 64.3333 72.5 69.75C70.7917 75.1667 68.3333 79.7708 65.125 83.5625C61.9167 87.3542 58.0625 90.25 53.5625 92.25C49.1042 94.25 44.1042 95.25 38.5625 95.25C33.1875 95.25 28.25 94.25 23.75 92.25C19.2917 90.25 15.4167 87.3542 12.125 83.5625C8.875 79.7708 6.35417 75.1667 4.5625 69.75C2.77083 64.3333 1.875 58.2083 1.875 51.375V45.625C1.875 38.7917 2.75 32.6875 4.5 27.3125C6.29167 21.8958 8.8125 17.2917 12.0625 13.5C15.3125 9.66667 19.1667 6.75 23.625 4.75C28.125 2.75 33.0625 1.75 38.4375 1.75C43.9792 1.75 49 2.75 53.5 4.75C58 6.75 61.8542 9.66667 65.0625 13.5C68.2708 17.2917 70.7292 21.8958 72.4375 27.3125C74.1875 32.6875 75.0625 38.7917 75.0625 45.625ZM63.125 51.375V45.5C63.125 40.0833 62.5625 35.2917 61.4375 31.125C60.3542 26.9583 58.75 23.4583 56.625 20.625C54.5 17.7917 51.9167 15.6458 48.875 14.1875C45.8333 12.7292 42.3542 12 38.4375 12C34.7292 12 31.3542 12.7292 28.3125 14.1875C25.3125 15.6458 22.7292 17.7917 20.5625 20.625C18.3958 23.4583 16.7292 26.9583 15.5625 31.125C14.3958 35.2917 13.8125 40.0833 13.8125 45.5V51.375C13.8125 56.8333 14.3958 61.6667 15.5625 65.875C16.7292 70.0417 18.3958 73.5625 20.5625 76.4375C22.7708 79.2708 25.3958 81.4167 28.4375 82.875C31.4792 84.3333 34.8542 85.0625 38.5625 85.0625C42.4792 85.0625 45.9583 84.3333 49 82.875C52.0417 81.4167 54.6042 79.2708 56.6875 76.4375C58.8125 73.5625 60.4167 70.0417 61.5 65.875C62.5833 61.6667 63.125 56.8333 63.125 51.375Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1_2_9)" />
                <path d="M93.6875 3H123.812C130.646 3 136.417 4.04167 141.125 6.125C145.875 8.20833 149.479 11.2917 151.938 15.375C154.438 19.4167 155.688 24.3958 155.688 30.3125C155.688 34.4792 154.833 38.2917 153.125 41.75C151.458 45.1667 149.042 48.0833 145.875 50.5C142.75 52.875 139 54.6458 134.625 55.8125L131.25 57.125H102.938L102.812 47.3125H124.188C128.521 47.3125 132.125 46.5625 135 45.0625C137.875 43.5208 140.042 41.4583 141.5 38.875C142.958 36.2917 143.688 33.4375 143.688 30.3125C143.688 26.8125 143 23.75 141.625 21.125C140.25 18.5 138.083 16.4792 135.125 15.0625C132.208 13.6042 128.438 12.875 123.812 12.875H105.75V94H93.6875V3ZM146.875 94L124.75 52.75L137.312 52.6875L159.75 93.25V94H146.875Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1_2_9)" />
                <path d="M242.562 3V94H230.438L184.625 23.8125V94H172.562V3H184.625L230.625 73.375V3H242.562Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1_2_9)" />
                <path d="M321.75 84.1875V94H273.562V84.1875H321.75ZM276 3V94H263.938V3H276ZM315.375 42.125V51.9375H273.562V42.125H315.375ZM321.125 3V12.875H273.562V3H321.125Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1_2_9)" />
                <path d="M371.562 3V94H359.688V3H371.562ZM400.812 3V12.875H330.5V3H400.812Z" stroke="white" strokeWidth="2" mask="url(#path-1-outside-1_2_9)" />
            </svg>
        </div>
    </>
}
export default Loading;