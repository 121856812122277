import { Layout } from 'antd'
import React, { ReactElement } from 'react'

interface Props {
  children: ReactElement
}
const NothingLayout: React.FC<Props> = ({ children }) => {
  return (
    <Layout>
      <Layout.Content>{children}</Layout.Content>
    </Layout>
  )
}
export default NothingLayout