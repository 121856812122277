import { message } from "antd";
import { RcFile } from "antd/lib/upload";
import moment, { lang } from "moment";

export const getUser = () => {
  const user = window.localStorage.getItem("userInfo");
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

export const isAdminQRNet = () => {
  const role = getUser().role
  switch (role) {
    case "admin":
      return true
      break
    case "admin_org":
      return false
      break
    default:
      return false
  }
}

export const saveUser = (userInfo: any) => {
  window.localStorage.setItem("userInfo", JSON.stringify(userInfo));
};

export const saveIsEditStatus = (edit: boolean) => {
  window.localStorage.setItem("isEdit", JSON.stringify(edit));
};

export const saveAdminOrganization = (info: any) => {
  window.localStorage.setItem("adminOrg", JSON.stringify(info));
};

export const getUserAdminOrganization = () => {
  const user = window.localStorage.getItem("adminOrg");
  if (user) {
    return JSON.parse(user);
  }
  return null;
};
export const saveDefaultAdminOrganization = (info: any) => {
  window.localStorage.setItem("defaultAdminOrg", JSON.stringify(info));
};

export const getDefaultAdminOrganization = () => {
  const user = window.localStorage.getItem("defaultAdminOrg");
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

export const editStatus = () => {
  const isEdit = window.localStorage.getItem("isEdit");
  if (isEdit) {
    return JSON.parse(isEdit);
  }
  return null;
};

export const removeUserInfo = () => {
  if (typeof window.localStorage.removeItem !== "undefined") {
    window.localStorage.removeItem("userInfo");
    window.localStorage.removeItem("defaultAdminOrg");
  }
};

export const saveStepsImportProfiles = (steps: any) => {
  window.localStorage.setItem("stepsImportProfiles", JSON.stringify(steps));
};

export const stepsImportProfiles = () => {
  const result = window.localStorage.getItem("stepsImportProfiles");
  if (result) {
    return JSON.parse(result);
  }
  return null;
};

export const dataImport = () => {
  const result = window.localStorage.getItem("dataImport");
  if (result) {
    return JSON.parse(result);
  }
  return null;
};

export const dataImportProfiles = () => {
  const result = window.localStorage.getItem("dataImport");
  if (result) {
    return JSON.parse(result);
  }
  return null;
};

export const isEmailValid = (email: string = "") => {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.trim());
};

export const validateListEmail = (emails: any) => {
  if (emails[0] === "") return true;
  // regular expression to validate email
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emails.every((email: any) => emailRegex.test(email.trim()));
};

export const validateListDomain = (domains: any) => {
  const regex = /^[a-zA-Z0-9][a-zA-Z0-9-.]*[a-zA-Z0-9]$/;
  const result: any = [];
  for (let i = 0; i < domains.length; i++) {
    const item = domains[i].trim();
    if (!item.includes(".")) return false;
    if (item.includes("*")) {
      const parts = item.split(".");
      if (parts.length === 2 && parts[0] === "*") {
        if (regex.test(parts[1])) {
          result.push(item);
        }
      } else {
        let check = false
        for (let j = 0; j < parts.length; j++) {
          if (regex.test(parts[j]) && parts[0] === "*") {
            check = true
          }
        }
        if (check) {
          result.push(item)
        }
      }
    } else {
      if (regex.test(item)) {
        result.push(item);
      }
    }
  }
  if (domains.length !== result.length) {
    return false;
  }
  return domains.every((item: any, index: any) => {
    return item.trim() === result[index];
  });
};

export const isUrl = (url: string) => {
  let re =
    /^$|^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/;
  return re.test(url);
};

export const isNumber = (number: any) => {
  let re = /^[0-9]*$/;
  return re.test(number);
};

export const isPhoneNumber = (number: any) => {
  let re = /^$|^(\+\d{1,3}[- ]?)?\d{9,10}$/;
  return re.test(number);
};

export const formatPhoneNumberVie = (phone: any) => {
  if (phone[0] === "+" && phone[1] === "8" && phone[2] === "4") {
    return phone.substring(3);
  }
  if (phone.charAt(0) === "0") {
    return phone.slice(1);
  }
  return phone;
};

export const subString = (text: string, number: number) => {
  return text.substring(0, number);
};

export const removeUserSession = () => {
  removeUserInfo();
};

export const capitalizeText = (arr: any = "") => {
  const result = arr?.split(" ");
  const _result = result.map(
    (item: any) => item.charAt(0).toUpperCase() + item.slice(1)
  );
  return _result.join(" ");
};

export const formatTime = (time: any) => {
  let minutes: any = Math.floor(time / 60);
  let seconds: any = Math.floor(time - minutes * 60);
  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;
  return minutes + ":" + seconds;
};

export const uniqueArray = (arr: any) => {
  let newArr: any = [];
  newArr = arr.filter((item: any) => {
    return newArr.includes(item) ? "" : newArr.push(item);
  });
  return newArr;
};

export const arraysEqual = (array1: any, array2: any) => {
  if (array1.length !== array2.length) {
    return false;
  }
  return array1.every(function (element: any, index: any) {
    return element === array2[index];
  });
};

export const showMsg = (
  type: any,
  content: string,
  duration: number = 0,
  disabled: boolean
) => {
  let msg = () => { };
  msg = message.open({
    type,
    content,
    duration,
  });
  if (disabled) {
    message.destroy();
    msg = message.open({
      type,
      content,
      duration,
    });
  }
  return msg;
};

export const formatDate = (time: any) => {
  let date = "";
  const language: any = window.localStorage.getItem("lang");
  const dateNow = moment(new Date().getTime());
  const _date = moment(time);
  if (dateNow.diff(_date, "year") !== 0) {
    date = moment(_date)
      .locale(language)
      .format(language === "vi" ? "DD MMM, YYYY" : "DD MMMM YYYY");
  } else {
    if (dateNow.diff(_date, "year") === 0 && dateNow.diff(_date, "days") > 7) {
      date = moment(_date).locale(language).format("DD MMMM");
    }

    if (
      dateNow.diff(_date, "hours") >= 1 &&
      dateNow.diff(_date, "hours") < 24
    ) {
      date =
        language === "en"
          ? dateNow.diff(_date, "hours") + "h"
          : dateNow.diff(_date, "hours") + " giờ";
    }
    if (
      dateNow.diff(_date, "month") === 0 &&
      dateNow.diff(_date, "hours") > 24 &&
      // dateNow.diff(_date, "days") < 1 &&
      dateNow.diff(_date, "days") <= 7
    ) {
      date =
        language === "en"
          ? dateNow.diff(_date, "days") + "d"
          : dateNow.diff(_date, "days") + " ngày";
    }
    if (dateNow.diff(_date, "minutes") < 60) {
      date =
        window.localStorage.getItem("lang") === "en"
          ? dateNow.diff(_date, "minutes") + "m"
          : dateNow.diff(_date, "minutes") + " phút";
    }
    if (dateNow.diff(_date, "minutes") === 0) {
      date = "Now";
    }
  }
  return date;
};

export const getValidUrl = (url = "") => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, "");

  if (/^(:\/\/)/.test(newUrl)) {
    return `https${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `https://${newUrl}`;
  }

  return newUrl;
};

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });