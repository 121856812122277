import React, { ReactElement } from 'react'
import { Layout } from 'antd';
import BasicHeader from './BasicHeader';
import BasicSidebar from './BasicSidebar';
import { getUser } from '../../utils/common';
import { useLocation } from 'react-router-dom';

interface Props {
  children: ReactElement
}

export const BasicLayout: React.FC<Props> = ({ children }) => {
  const userInfo = getUser()

  return (
    <div>
      <Layout>
        <BasicSidebar />
        <Layout>
          <BasicHeader />
          <Layout.Content>
            <div className={userInfo.role === "admin" ? "bgWhite" : "bgWhite pt-0"} style={{ minHeight: "100vh" }}>
              {children}
            </div>
          </Layout.Content>
        </Layout>
      </Layout>
    </div>
  )
}

export default BasicLayout