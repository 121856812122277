import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const Authentications = (props: any) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (pathname === "/login") {
            navigate("/")
        }
    }, [pathname])
    return props?.children
}

export default Authentications