export const IconGG = () => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_8832_47669)">
            <path d="M9.99829 4.0902C11.3865 4.08935 12.7301 4.5799 13.7912 5.47494L16.7634 2.64456C15.6224 1.59253 14.252 0.820899 12.7608 0.390919C11.2696 -0.0390612 9.69874 -0.115559 8.17283 0.167498C6.64692 0.450554 5.20799 1.08537 3.97023 2.02157C2.73246 2.95777 1.72995 4.16958 1.04224 5.56083L4.39297 8.14353C4.78375 6.96516 5.53538 5.9396 6.54139 5.21212C7.5474 4.48465 8.75681 4.09215 9.99829 4.0902Z" fill="#D94F3D" />
            <path d="M4.09014 9.9979C4.09098 9.36745 4.19317 8.74125 4.39281 8.14325L1.04207 5.56055C0.356681 6.93933 0 8.45815 0 9.9979C0 11.5376 0.356681 13.0565 1.04207 14.4352L4.39281 11.8526C4.19317 11.2545 4.09098 10.6283 4.09014 9.9979Z" fill="#F2C042" />
            <path d="M19.5872 8.17969H10.0436V12.2698H15.4517C15.1299 13.4263 14.3929 14.4235 13.3816 15.0702L16.7064 17.6334C18.831 15.7265 20.0794 12.6257 19.5872 8.17969Z" fill="#5085ED" />
            <path d="M13.3804 15.0715C12.3529 15.6597 11.1815 15.949 9.99829 15.9068C8.75681 15.9049 7.54741 15.5124 6.54139 14.7849C5.53538 14.0575 4.78375 13.0319 4.39297 11.8535L1.04224 14.4362C1.86987 16.1046 3.14659 17.5089 4.72879 18.4913C6.311 19.4737 8.13592 19.9951 9.99829 19.997C12.4489 20.0636 14.8376 19.2219 16.7052 17.6338L13.3804 15.0715Z" fill="#57A75C" />
        </g>
        <defs>
            <clipPath id="clip0_8832_47669">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
}