import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as queryString from "query-string"

import { setDefautlAdminOrg, setIsRefresh, setLoading, setIsShowSidebar } from "../../redux/actions/common-actions";
import { getListOrganizationAdmin } from "../../api/organization-api";

import { UserOutlined, QuestionCircleOutlined, MenuUnfoldOutlined, DownOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Layout, Menu, Button, Dropdown, Modal, Avatar, Space, Row, Col, Tooltip } from "antd"
import { getDefaultAdminOrganization, getUser, removeUserInfo, saveDefaultAdminOrganization, saveUser } from "../../utils/common";
import IconVie from "../../assets/icon/IconVie";
import IconEn from "../../assets/icon/IconEn";

const BasicHeader = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userInfo = getUser()
    const defaultAdmin = getDefaultAdminOrganization()
    const isLoading = useSelector((state: any) => state.commonReducer.isLoading || false)
    const adminOrg = useSelector((state: any) => state.commonReducer.info || (defaultAdmin ? defaultAdmin : null))
    const isShowSidebar = useSelector((state: any) => state.commonReducer.isShowSidebar || false)
    const isRefresh = useSelector((state: any) => state.commonReducer.isRefresh || false)
    const { search, pathname } = useLocation()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [listAdminOrg, setListAdminOrg] = useState<[]>()
    const [showDropdown, setShowDropDown] = useState<boolean>(true)
    const _search = queryString.parse(search)

    const handleSidebar = () => {
        dispatch(setIsShowSidebar(!isShowSidebar))
    }

    const handleChangeLanguage = (language: string) => {
        window.location.reload()
        i18n.changeLanguage(language)
        window.localStorage.setItem("lang", language)
        dispatch(setIsRefresh(new Date().getTime()))
    }

    const handleLogout = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        removeUserInfo()
        navigate("/")
        window.location.reload()
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleChangeOrg = async (org: any) => {
        dispatch(setDefautlAdminOrg(org))
        saveDefaultAdminOrganization(org)
        navigate(`/overview?id=${org.organization_id}`)
        dispatch(setIsRefresh(new Date().getTime()))
    }

    const menu = (
        <Menu>
            <Menu.Item key='logout' icon={<UserOutlined />} onClick={handleLogout}>
                {t("Logout")}
            </Menu.Item>
        </Menu>
    );

    const changeLanguage = (
        <Menu style={{ width: 150 }}>
            <Menu.Item key="vi" onClick={() => handleChangeLanguage("vi")}>
                <Space>
                    <IconVie />
                    {t("Vietnamese")}
                </Space>
            </Menu.Item>
            <Menu.Item key="eng" onClick={() => handleChangeLanguage("en")}>
                <Space>
                    <IconEn />
                    {t("English")}
                </Space>
            </Menu.Item>
        </Menu>
    )
    // menu for organization
    const menuOrg = (
        <Menu className="list_org_admin">
            {listAdminOrg?.map((item: any) => {
                return (
                    <Menu.Item key={item?.organization_id} className={(item?.organization_id === Number(_search.id)) ? "active" : ""} onClick={() => handleChangeOrg(item)}>
                        <div className="flexGroup h-full mr-10 ml-10" style={{ justifyContent: "flex-start" }}>
                            <Avatar shape="square" icon={<UserOutlined />} size={24} src={item?.logo_image_url !== "" && item?.logo_image_url} />
                            &nbsp;&nbsp;
                            <div className="flexGroup h-full" style={{ flex: 1, flexDirection: "column", alignItems: "flex-start" }}>
                                <Tooltip placement="top" title={item?.organization_name}>
                                    <div className="listOrg_title">
                                        <h3>{item?.organization_name}</h3>
                                    </div>
                                </Tooltip>
                                <div>
                                    <span style={{ fontSize: 12 }}>{t("Tax code")}: {item.tax_number}</span>
                                </div>
                            </div>
                        </div>
                    </Menu.Item>
                )
            })}
        </Menu>
    )

    useEffect(() => {
        // get list org admin
        if (userInfo.role === "admin_org") {
            const query = queryString.parse(search)
            const getListAdminOrg = async () => {
                let data: any = {}
                data.page = query?.page || 1
                data.page_size = query?.page_size || 100
                data.type = "enterprise"
                const result = await getListOrganizationAdmin(data)
                dispatch(setLoading(true))
                try {
                    if (result.status === "success") {
                        if (result?.data?.data?.length > 1) {
                            setShowDropDown(false)
                        }
                        dispatch(setLoading(false))
                        setListAdminOrg(result?.data?.data)
                    }
                } catch (error) {
                    dispatch(setLoading(false))
                    throw error
                }
            }
            getListAdminOrg()
        }
    }, [isRefresh])

    // if (isLoading) {
    //     return <></>
    // }

    return (
        <Layout.Header className="header flexGroup" style={{ justifyContent: "space-between" }} >
            <div className="flexGroup" style={{ cursor: "pointer" }}>
                <div className="toggle-sidebar">
                    <Button icon={isShowSidebar ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} onClick={handleSidebar} />
                </div>
                {userInfo?.role === "admin_org" && (
                    <Dropdown className="ml-25" overlay={menuOrg} disabled={showDropdown} trigger={["click"]}>
                        <div className="flexGroup h-full" style={{ lineHeight: "20px" }}>
                            <div className="flexGroup h-full mr-10 ml-10" style={{ justifyContent: "flex-start" }}>
                                {adminOrg && <Avatar shape="square" icon={<UserOutlined />} size={24} src={adminOrg?.logo_image_url !== "" && adminOrg?.logo_image_url} />}
                                &nbsp;&nbsp;
                                <div className="flexGroup" style={{ flexDirection: "column", alignItems: "flex-start" }}>
                                    <Tooltip placement="rightTop" title={adminOrg?.organization_name}>
                                        <div className="header_title" style={{ flexDirection: "column", alignItems: "flex-start", gap: 5 }}>
                                            <h3 style={{ margin: 0, lineHeight: "20px" }}>{adminOrg?.organization_name}</h3>
                                        </div>
                                    </Tooltip>
                                    <div>
                                        {adminOrg && (
                                            <span style={{ fontSize: 12 }}>{`${t("Tax code")}: ${adminOrg?.tax_number}`}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {(listAdminOrg && listAdminOrg?.length > 1) && <DownOutlined style={{ fontSize: 14 }} />}
                        </div>
                    </Dropdown>
                )
                }
            </div>

            <div className="account h-full flexGroup">
                {/* <Row gutter={[25, 0]} >
                    <Col span={8}>
                        <IconSearch />
                    </Col>
                    <Col span={8}>
                        <IconReport />
                    </Col>
                    <Col span={8}>
                        <IconNoti />
                    </Col>
                </Row> */}

                <Dropdown overlay={menu} trigger={['click']} className="ml-30 mr-25" >
                    <div className="flexGroup h-full mr-10 ml-10">
                        <Avatar icon={<UserOutlined />} size={24} />
                        &nbsp;&nbsp;
                        <span className="linkDetail">
                            {userInfo.email}
                        </span>
                    </div>
                </Dropdown>

                <Dropdown overlay={changeLanguage} trigger={['click']}>
                    <Space>
                        {/* <IconLanguage /> */}
                        {i18n.language === 'vi' ? <IconVie /> : <IconEn />}
                    </Space>
                </Dropdown>
            </div>

            <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Space>
                    <QuestionCircleOutlined />
                    {t("Logout")}
                </Space>
                <p className="mt-15">{t("Are you sure you want to exit?")}</p>
            </Modal>
        </Layout.Header >
    )
}

export default BasicHeader 