import React, { Suspense, useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import * as queryString from "query-string"

import { Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons'

import { routes, routesForOrg } from '../../routes/routes';
import { getDefaultAdminOrganization, getUser, saveDefaultAdminOrganization } from '../../utils/common';
import { getListOrganizationAdmin } from '../../api/organization-api';
import { setDefautlAdminOrg, setIsRefresh, setIsShowModal } from '../../redux/actions/common-actions';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

import Authentications from './Authentications';
import Loading from '../common/Loading';
import Login from '../common/login/Login';

import 'antd/dist/antd.min.css'
import '../../assets/scss/styles.scss'
import '../../assets/style/styles.css'

const App = () => {
  const user = getUser()
  const navigate = useNavigate()
  const defaultAdmin = getDefaultAdminOrganization()
  const { search, pathname } = useLocation()
  const dispatch = useDispatch()
  const isLoading = useSelector((state: any) => state.commonReducer.isLoading || false)
  const isRefresh = useSelector((state: any) => state.commonReducer.isRefresh || false)

  useEffect(() => {
    if (!user) {
      navigate("/")
    }
    if (defaultAdmin) return
    if (user?.role === "admin_org") {
      const getListAdminOrg = async () => {
        const query = queryString.parse(search)
        let data: any = {}
        data.page = query?.page || 1
        data.page_size = query?.page_size || 100
        data.type = "enterprise"
        const result = await getListOrganizationAdmin(data)
        try {
          if (result.status === "success") {
            dispatch(setDefautlAdminOrg(result?.data?.data[0]))
            saveDefaultAdminOrganization(result?.data?.data[0])
            dispatch(setIsRefresh(new Date().getDay()))
            if (result?.data?.data?.length < 2) {
              dispatch(setIsShowModal(false))
            }
          }
        } catch (error) {
          throw error
        }
      }
      getListAdminOrg()
    }
  }, [isRefresh])

  return (
    <>
      {user ? (
        <section className='page-body bgWhite'>
          <Authentications>
            <Suspense fallback={<Loading />}>
              <Spin
                spinning={isLoading}
                indicator={<Loading3QuartersOutlined spin />}
              >
                <Routes>
                  {(user.role === "admin" ? routes : routesForOrg).map(({ component: Component, name, path, layout: Layout }) => {
                    return (
                      <Route
                        key={name}
                        path={path}
                        element={(
                          <Layout>
                            <Component />
                          </Layout>
                        )}
                      />
                    )
                  })}
                </Routes>
              </Spin>
            </Suspense>
          </Authentications>
        </section>
      ) : (
        <Spin
          spinning={isLoading}
          indicator={<Loading3QuartersOutlined spin />}
        >
          <Routes>
            <Route path="/linkedin" element={<LinkedInCallback />} />
            <Route path="/" element={<Login />} />
          </Routes>
        </Spin>
      )}
    </>
  );
}

export default App;
