import LoginAdmin from "../../screens/Login/LoginAdmin";
import LoginTeam from "../../screens/Login/LoginTeam";

const Login = () => {
  const url = window.location.host;
  // window.location.host is subdomain.domain.com
  const parts = url.split(".");
  const subDomain = parts[0];

  return (
    <>
      {(subDomain === "test-team" || subDomain === "team") && <LoginTeam />}
      {(subDomain === "test-admin" || subDomain === "admin") && <LoginAdmin />}
    </>
  );
};

export default Login;
