import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Input, Row, message, Dropdown, Space, Menu, notification, Modal } from 'antd'
import { DownOutlined, LeftOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { ValidateForm } from '../../../interface/common'
import { saveUser } from '../../../utils/common'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { requestPhoneLogin, getPhonePermissionsets, getEmailPermissionsetsForTeam, requestEmailLoginForTeam } from '../../../api/user-api'
import imgLogin from '../../../assets/images/Login.png'
import { useTranslation } from 'react-i18next'
import IconVie from '../../../assets/icon/IconVie'
import IconEn from '../../../assets/icon/IconEn'
import { LogoLogin } from '../../../assets/icon/LogoLogin'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import LoginWithGoogle from '../../common/login/LoginWithGoogle'
import LoginWithFacebook from '../../common/login/LoginWithFacebook'
import LoginWithLinkedIn from '../../common/login/LoginWithLinkedin'
import CountDown from '../../common/CountDown'
import { setIsTimeEnd } from '../../../redux/actions/common-actions'

const LoginTeam = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [languages, setLanguages] = useState<any>(window.localStorage.getItem("lang"))
    const [loading, setLoading] = useState<boolean>(false)
    const [verificationId, setVerificationId] = useState<number>()
    const [phoneCode, setPhoneCode] = useState<string>()
    const [phone, setPhone] = useState<any>()
    const [stepLogin, setStepLogin] = useState<{ type: string, verifying: boolean, verified: boolean }>({ type: "phone", verifying: false, verified: false })
    const [validationEmail, setValidationEmail] = useState<ValidateForm>({ status: "success", msg: "" })
    const [validationPhone, setValidationPhone] = useState<ValidateForm>({ status: "success", msg: "" })
    const [validationCode, setValidationCode] = useState<ValidateForm>({ status: "success", msg: "" })
    const [showModal, setShowModal] = useState<{ show: boolean, msg: "" }>({ show: false, msg: "" })
    const [countResend, setCountResend] = useState<number>(3)
    const [countDown, setCountDown] = useState<number>(300)
    const isTimeEnd = useSelector((state: any) => state.commonReducer.isTimeEnd || false)

    const changeLanguage = (
        <Menu style={{ width: 150 }}>
            <Menu.Item key="vi" onClick={() => handleChangeLanguage("vi")}>
                <Space>
                    <IconVie />
                    {t("Vietnamese")}
                </Space>
            </Menu.Item>
            <Menu.Item key="eng" onClick={() => handleChangeLanguage("en")}>
                <Space>
                    <IconEn />
                    {t("English")}
                </Space>
            </Menu.Item>
        </Menu>
    )

    const handleChangePhone = (value: any, data: any, event: any, formattedValue: any) => {
        const _phone = value.replace(`${data.dialCode}`, "")
        setPhone(_phone)
        setPhoneCode(`+${data.dialCode}`)
    }
    // send OTP
    const handleSendOTP = async () => {
        // setCounter(100)
        setLoading(true)
        try {
            // login with email
            if (stepLogin.type === "email") {
                let data = {
                    email: form.getFieldValue("email")
                }
                if (form.getFieldValue("email")?.length < 1) {
                    setLoading(false)
                    setValidationEmail({ status: "error", msg: t("Please fill your email!") })
                    setStepLogin({ type: "email", verifying: false, verified: false })
                    return
                }
                const result = await requestEmailLoginForTeam(data)
                if (result.status === "success") {
                    setLoading(false)
                    setVerificationId(result?.data?.verification_id)
                    setStepLogin({ type: "email", verifying: true, verified: false })
                } else {
                    setLoading(false)
                    setValidationEmail({ msg: t("Your email invalid!"), status: "error" })
                }
            }
            // login with phone
            if (stepLogin.type === "phone") {
                let data = {
                    phone: phone,
                    phone_code: phoneCode
                }
                if (form.getFieldValue("phone")?.length < 3) {
                    setValidationPhone({ status: "error", msg: t("Please enter your phone number!") })
                    setStepLogin({ type: "phone", verifying: false, verified: false })
                    setLoading(false)
                    return
                }
                if (form.getFieldValue("phone")?.length > 11 || form.getFieldValue("phone")?.length < 11) {
                    setValidationPhone({ status: "error", msg: t("Invalid type of phone number!") })
                    setStepLogin({ type: "phone", verifying: false, verified: false })
                    setLoading(false)
                    return
                }
                const result = await requestPhoneLogin(data)
                if (result.status === "success") {
                    setLoading(false)
                    setVerificationId(result?.data?.verification_id)
                    setStepLogin({ type: "phone", verifying: true, verified: false })
                }
            }
        } catch (error) {
            setLoading(false)
            throw error
        }
    }

    // sign in
    const handleSignIn = async () => {
        setLoading(true)
        try {
            // verified email
            if (stepLogin.type === "email") {
                let data: any = {
                    email: form.getFieldValue("email"),
                    verification_id: verificationId,
                    code: form.getFieldValue("code")
                }
                const result = await getEmailPermissionsetsForTeam(data)
                if (result.status === "success") {
                    setLoading(false)
                    if (!result?.data?.role || result?.data?.role !== "admin_org") {
                        setShowModal({ show: true, msg: t("Invalid user") })
                    } else {
                        saveUser({
                            email: form.getFieldValue("email"),
                            accessToken: result?.data.access_token,
                            expires: result?.data.expires_in,
                            role: result?.data?.role,
                            organization: result?.data?.organization
                        })
                        window.location.reload()
                        navigate('/')
                    }
                }
                if (result.status === "error" && result.status === "VERIFICATION_USER_INACTIVE") {
                    setLoading(false)
                    setShowModal({ show: true, msg: t("Invalid user") })
                    setValidationCode({ status: "success", msg: "" })
                    setCountResend(3)
                }
                if (result.status === "error") {
                    setLoading(false)
                    setCountResend((prev: any) => (prev - 1))
                    setValidationCode({
                        status: "error", msg: t(`OTP code is invalid.`) + " " + t(`You have`) + " " + t(`${countResend - 1}`) + " " + t(`more chances.`)
                    })
                    if (countResend === 1) {
                        setShowModal({ show: true, msg: t("You have entered too many times. Please return to the login screen") })
                        setValidationCode({ status: "success", msg: "" })
                        setCountResend(3)
                    }
                }
            }
            // verified phone
            if (stepLogin.type === "phone") {
                let data: any = {
                    phone: phone,
                    phone_code: phoneCode,
                    verification_id: verificationId,
                    code: form.getFieldValue("code")
                }
                const result = await getPhonePermissionsets(data)
                if (result.status === "success") {
                    setLoading(false)
                    if (!result?.data?.role) {
                        setShowModal({ show: true, msg: t("Invalid user") })
                    } else {
                        saveUser({
                            email: `+${form.getFieldValue("phone")}`,
                            accessToken: result?.data.access_token,
                            expires: result?.data.expires_in,
                            role: result?.data?.role,
                            organization: result?.data?.organization
                        })
                        window.location.reload()
                        navigate('/')
                    }
                }
                if (result.status === "error") {
                    setLoading(false)
                    setCountResend((prev: any) => (prev - 1))
                    setValidationCode({
                        status: "error", msg: t(`OTP code is invalid.`) + " " + t(`You have`) + " " + (countResend - 1) + " " + t(`more chances.`)
                    })
                    if (countResend === 1) {
                        setShowModal({ show: true, msg: t("You have entered too many times. Please return to the login screen") })
                        setValidationCode({ status: "success", msg: "" })
                        setCountResend(3)
                    }
                }
            }
        } catch (error) {
            setLoading(false)
            throw error
        }
    }

    const handleResendOTP = async () => {
        setCountDown(300)
        dispatch(setIsTimeEnd(false))
        try {
            // login with email
            if (stepLogin.type === "email") {
                let data = {
                    email: form.getFieldValue("email")
                }
                const result = await requestEmailLoginForTeam(data)
                if (result.status === "success") {
                    setVerificationId(result?.data?.verification_id)
                    setStepLogin({ type: "email", verifying: true, verified: false })
                } else {
                    setValidationEmail({ msg: t("Your email invalid!"), status: "error" })
                }
            }
            // login with phone
            if (stepLogin.type === "phone") {
                let data = {
                    phone: phone,
                    phone_code: phoneCode
                }
                const result = await requestPhoneLogin(data)
                if (result.status === "success") {
                    setVerificationId(result?.data?.verification_id)
                    setStepLogin({ type: "phone", verifying: true, verified: false })
                }
            }
        } catch (error) {
            setLoading(false)
            throw error
        }
    }

    const handleChangeLanguage = (language: string) => {
        setLanguages(language)
        i18n.changeLanguage(language)
        window.localStorage.setItem("lang", language)
    }

    useEffect(() => {
        // format form
        form.setFieldsValue({
            email: "",
            phone_code: "",
            phone: ""
        })
    }, [])

    return (
        <>
            <Form form={form}>
                <Row gutter={[40, 0]} className="login_section">
                    <Col span={12}>
                        <div className="login">
                            <div className='login_header'>
                                <LogoLogin />
                            </div>

                            <div className='form_login'>
                                <div className="login_main">
                                    {/* when not verifyng */}
                                    {!stepLogin.verifying && (
                                        <>
                                            <p className="login_desc mb-0">
                                                {t("Welcome back!")}
                                            </p>
                                            <p className='login_title mb-20'>
                                                {t("Sign in")}
                                            </p>
                                            <div className='d-flex' style={{ justifyContent: "space-between" }}>
                                                {stepLogin.type === "email" && <>
                                                    <span style={{ fontWeight: 500 }}>{t("Email")}</span>
                                                    <Button type='link' className='p-0' onClick={() => {
                                                        form.setFieldValue("phone", "")
                                                        setStepLogin((prev: any) => ({ ...prev, type: "phone" }))
                                                        setValidationPhone({ msg: "", status: "success" })
                                                    }}>
                                                        <span style={{ fontWeight: 500, color: "#0A66C2" }}>
                                                            {t("Sign in with phone number")}
                                                        </span>
                                                    </Button>
                                                </>}
                                                {stepLogin.type === "phone" && <>
                                                    <span style={{ fontWeight: 500 }}>{t("Phone number")}</span>
                                                    <Button type='link' className="p-0" onClick={() => {
                                                        form.setFieldValue("email", "")
                                                        setStepLogin((prev: any) => ({ ...prev, type: "email" }))
                                                        setValidationEmail({ msg: "", status: "success" })
                                                    }}>
                                                        <span style={{ fontWeight: 500, color: "#0A66C2" }}>
                                                            {t("Sign in with email")}
                                                        </span>
                                                    </Button>
                                                </>}
                                            </div>
                                            {stepLogin.type === "email" && (
                                                <Form.Item
                                                    style={{ marginBottom: 16 }}
                                                    name="email"
                                                    help={validationEmail.msg}
                                                    validateStatus={validationEmail.status}
                                                    normalize={(value) => {
                                                        if (value) {
                                                            setValidationEmail({ status: "success", msg: "" })
                                                        }
                                                        return value
                                                    }}
                                                >
                                                    <Input size="large" placeholder={t("name@company.com")} />
                                                </Form.Item>
                                            )}
                                            {stepLogin.type === "phone" && (
                                                <Form.Item
                                                    style={{ marginBottom: 16 }}
                                                    name="phone"
                                                    help={validationPhone.msg}
                                                    validateStatus={validationPhone.status}
                                                    normalize={(value) => {
                                                        if (value) {
                                                            setValidationPhone({ status: "success", msg: "" })
                                                        }
                                                        return value
                                                    }}
                                                >
                                                    <PhoneInput
                                                        country={'vn'}
                                                        value={phone}

                                                        onChange={handleChangePhone}
                                                    />
                                                </Form.Item>
                                            )}
                                            <Button type="primary" loading={loading} className='button' style={{ float: "right" }} htmlType="submit" onClick={handleSendOTP}>
                                                {t("Send OTP")}
                                            </Button>
                                        </>
                                    )}

                                    {stepLogin.verifying && <>
                                        <Button type="link" className="btn-back" onClick={() => {
                                            form.setFieldValue("code", "")
                                            setStepLogin((prev: any) => ({ ...prev, verifying: false }))
                                            setValidationCode({ status: "success", msg: "" })
                                            setCountResend(3)
                                        }}>
                                            <LeftOutlined style={{ fontSize: 13, fontWeight: 500 }} />
                                            {t("Back")}
                                        </Button>
                                        {stepLogin.type === "email" && (
                                            <p className='mb-5 semibold'>
                                                {t("We have sent OTP to") + " " + form.getFieldValue("email")}
                                            </p>
                                        )}
                                        {stepLogin.type === "phone" && (
                                            <p className='mb-5 semibold'>
                                                {t("We have sent OTP to phone number") + " " + `+${form.getFieldValue("phone")}`}
                                            </p>
                                        )}
                                        <Form.Item
                                            style={{ marginBottom: 16 }}
                                            name="code"
                                            help={validationCode.msg}
                                            validateStatus={validationCode.status}
                                            normalize={(value) => {
                                                if (value) {
                                                    setValidationCode({ status: "success", msg: "" })
                                                }
                                                return value
                                            }}
                                        >
                                            <Input style={{ height: 40 }} maxLength={6} suffix={
                                                <>
                                                    {(!isTimeEnd) ? (
                                                        <Button disabled type="link" className="btn-resend">
                                                            {t("Resend after")}
                                                            &nbsp;
                                                            <CountDown seconds={countDown} />
                                                        </Button>
                                                    ) : (
                                                        <Button type="link" className="btn-resend" onClick={handleResendOTP}>{`${t("Resend")}`}</Button>
                                                    )}
                                                </>
                                            }
                                            />
                                        </Form.Item>
                                        <Button type="primary" disabled={countResend === 0} loading={loading} className='button' style={{ float: "right" }} htmlType="submit" onClick={handleSignIn}>
                                            {t("Sign in")}
                                        </Button>
                                    </>}
                                </div>

                                {!stepLogin.verifying && (
                                    <div className="login_footer">
                                        <div className='flexGroup' style={{ alignItems: "center" }}>
                                            <div className="line"></div>
                                            <p style={{ padding: "0 8px" }}>{t("Or")}</p>
                                            <div className="line"></div>
                                        </div>
                                        <div className="login_options mt-15">
                                            <LoginWithGoogle setShowModal={setShowModal} />
                                            <LoginWithFacebook setShowModal={setShowModal} />
                                            {/* <LoginWithLinkedIn setShowModal={setShowModal} /> */}
                                        </div>

                                    </div>
                                )}
                            </div>

                            <p className="footer_text">
                                <span>{t("By continuing, you agree to QRnet’s")}</span>
                                &nbsp;
                                <a href={`https://qrnet.me/policy/term-and-condition?lang=${languages}`} target="_blank" className="term_policy">{t("Term of Service")}</a>
                                &nbsp;
                                <span>{t("and")}</span>
                                <br />
                                <a href={`https://qrnet.me/policy/privacy-policy?lang=${languages}`} target="_blank" className="term_policy">{t("Privacy Policy")}</a>
                            </p>

                        </div>
                    </Col>
                    <Col span={12} className="p-0 login_right" >
                        <Dropdown className="dropdownLanguage" overlay={changeLanguage} trigger={['click']} >
                            <Space style={{ cursor: "pointer" }}>
                                {/* <IconLanguage /> */}
                                {window.localStorage.getItem("lang") === "vi" ? <IconVie /> : <IconEn />}
                                <DownOutlined style={{ color: "white" }} />
                            </Space>
                        </Dropdown>
                        <div className='login_image'>
                            <img src={imgLogin} />
                        </div>
                    </Col>
                </Row>
            </Form >

            <Modal
                closable={false}
                open={showModal.show || countResend === 0}
                centered
                cancelButtonProps={{ style: { display: "none" } }}
                width={416}
                onOk={() => {
                    setShowModal({ show: false, msg: "" })
                    setStepLogin((prev: any) => ({ ...prev, verifying: false }))
                    form.setFieldValue("code", "")
                }}
                okText={
                    <span>{t("Back to login")}</span>
                }
            >
                <div className='d-flex' style={{ alignItems: "center" }}>
                    <CloseCircleOutlined className="mr-15" style={{ color: "#FF4D4F", fontSize: 22 }} />
                    <span style={{ fontSize: 16, fontWeight: 500, color: "rgba(0, 0, 0, 0.85)" }}>{t(showModal.msg)}</span>
                </div>
            </Modal>
        </>
    )
}

export default LoginTeam
