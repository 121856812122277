import { ACTIONS } from "../../constants/common"

const commonReducer = (state = {},action: {type: string,payload: any}) => {
    switch(action.type){
        case ACTIONS.SET_IS_LOADING: 
            return {...state, isLoading: action.payload}
        case ACTIONS.SET_IS_LOGGED_IN: 
            return {...state,isLogged: action.payload}
        case ACTIONS.SET_SIDEBAR_SHOW:
            return {...state,isShowSidebar: action.payload}  
        case ACTIONS.SET_DEFAULT_ADMIN_ORG:
            return {...state,userInfo: action.payload}    
        case ACTIONS.SET_IS_EDITING: 
            return {...state,isEditing: action.payload}
        case ACTIONS.SET_ADMIN_ORG: 
            return {...state, adminOrg: action.payload}
        case ACTIONS.SET_IS_REFRESH: 
            return {...state, isRefresh: action.payload}
        case ACTIONS.SET_IS_SHOW_MODAL: 
            return {...state, isShowModal: action.payload}
        case ACTIONS.SET_IS_TIME_END: 
            return {...state, isTimeEnd: action.payload}
        case ACTIONS.SET_IS_COLLAPSED:
            return {...state, isCollapsed: action.payload}
        default:
            return { ...state }
    }

}

export default commonReducer
