const IconEn = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5822_35408)">
                <rect y="3.42999" width="24" height="17.14" rx="3" fill="#1A47B8" />
                <path fillRule="evenodd" clipRule="evenodd" d="M2.55311 3.42999H0V6.28666L21.433 20.57H24V17.7133L2.55311 3.42999Z" fill="white" />
                <path d="M0.851541 3.42999L24 18.8965V20.57H23.1684L0 5.0875V3.42999H0.851541Z" fill="#F93939" />
                <path fillRule="evenodd" clipRule="evenodd" d="M21.7143 3.42999H24V6.28666C24 6.28666 9.15406 15.8028 2.28571 20.57H0V17.7133L21.7143 3.42999Z" fill="white" />
                <path d="M24 3.42999H23.2249L0 18.9098V20.57H0.851541L24 5.10002V3.42999Z" fill="#F93939" />
                <path fillRule="evenodd" clipRule="evenodd" d="M8.72809 3.42999H15.2919V8.7169H24V15.2796H15.2919V20.57H8.72809V15.2796H0V8.7169H8.72809V3.42999Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.1053 3.42999H13.8947V10.0223H24V13.9777H13.8947V20.57H10.1053V13.9777H0V10.0223H10.1053V3.42999Z" fill="#F93939" />
            </g>
            <defs>
                <clipPath id="clip0_5822_35408">
                    <rect y="3.42999" width="24" height="17.14" rx="3" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default IconEn