import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { setIsTimeEnd } from "../../redux/actions/common-actions"
import { formatTime } from "../../utils/common"

interface Props {
    seconds: any
}

const CountDown: React.FC<Props> = ({ seconds }) => {
    const [countdown, setCountdown] = useState<any>(seconds)
    const timerId = useRef<any>()
    const dispatch = useDispatch()

    useEffect(() => {
        timerId.current = setInterval(() => {
            setCountdown((prev: any) => prev - 1)
        }, 1000)

        return () => clearInterval(timerId.current)
    }, [])

    useEffect(() => {
        if (countdown <= 0) {
            clearInterval(timerId.current)
            dispatch(setIsTimeEnd(true))
        }
    }, [countdown])

    return <>
        <span>{formatTime(countdown)}</span>
    </>
}

export default CountDown