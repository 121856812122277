import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Spin } from "antd";
import { loginSocialMedia } from "../../../api/user-api";
import { getListOrganizationAdmin } from "../../../api/organization-api";
import { IconFacebook } from "../../../assets/icon/IconFacebook";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import {
  setDefautlAdminOrg,
  setIsRefresh,
} from "../../../redux/actions/common-actions";
import { saveDefaultAdminOrganization, saveUser } from "../../../utils/common";
import * as queryString from "query-string";

interface Props {
  setShowModal: any;
}

const LoginFacebook: React.FC<Props> = ({ setShowModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const responseFacebook = async (response: any) => {
    try {
      if (response) {
        setLoading(true);
        const token = await loginSocialMedia(
          { token: response?.accessToken },
          "facebook"
        );
        if (token?.status === "success") {
          setLoading(false);
          if (!token?.data?.role) {
            setShowModal({ show: true, msg: t("Invalid user") });
          } else {
            saveUser({
              email: response?.profileObj?.name,
              accessToken: token?.data.access_token,
              expires: token?.data.expires_in,
              role: token?.data?.role,
            });
            if (token?.role === "admin_org") {
              const getListAdminOrg = async () => {
                const query = queryString.parse(search);
                let data: any = {};
                data.page = query?.page || 1;
                data.page_size = query?.page_size || 100;
                data.type = "enterprise";
                const _result = await getListOrganizationAdmin(data);
                try {
                  if (_result.status === "success") {
                    dispatch(setDefautlAdminOrg(_result?.data?.data[0]));
                    saveDefaultAdminOrganization(_result?.data?.data[0]);
                    dispatch(setIsRefresh(new Date().getDay()));
                  }
                } catch (error) {
                  throw error;
                }
              };
              getListAdminOrg();
            }
            window.location.reload();
          }
        }
      } else {
        setLoading(false);
        setShowModal({ show: true, msg: t("Login error") });
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  if (loading) {
    return (
      <div className="btn-social">
        <Spin
          spinning={loading}
          indicator={
            <Loading3QuartersOutlined spin style={{ display: "block" }} />
          }
        />
      </div>
    );
  }

  return (
    <FacebookLogin
      appId={"502970545556959"}
      autoLoad={false}
      render={(renderProps) => (
        <button onClick={renderProps.onClick} className="btn-social">
          <IconFacebook />
        </button>
      )}
      callback={responseFacebook}
    />
  );
};

export default LoginFacebook;
