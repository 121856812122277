import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { gapi } from "gapi-script";
import { useDispatch } from "react-redux";
import * as queryString from "query-string"

import { Spin } from "antd"
import GoogleLogin from "react-google-login"
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { IconGG } from "../../../assets/icon/IconGG"
import { loginSocialMedia } from "../../../api/user-api";
import { saveDefaultAdminOrganization, saveUser } from "../../../utils/common";
import { getListOrganizationAdmin } from "../../../api/organization-api";
import { setDefautlAdminOrg, setIsRefresh } from "../../../redux/actions/common-actions";

interface Props {
    setShowModal: any
}

const LoginWithGoogle: React.FC<Props> = ({ setShowModal }) => {
    const { t } = useTranslation()
    const { search, pathname } = useLocation()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState<boolean>(false)

    const handleLoginGoogle = async (response: any) => {
        setLoading(true)
        try {
            if (response) {
                const token = await loginSocialMedia({ token: response?.accessToken }, 'google')
                if (token?.status === "success") {
                    setLoading(false)
                    if (!token?.data?.role) {
                        setShowModal({ show: true, msg: t("Invalid user") })
                    } else {
                        saveUser({
                            email: response?.profileObj?.name,
                            accessToken: token?.data.access_token,
                            expires: token?.data.expires_in,
                            role: token?.data?.role,
                        })
                        if (token?.role === "admin_org") {
                            const getListAdminOrg = async () => {
                                const query = queryString.parse(search)
                                let data: any = {}
                                data.page = query?.page || 1
                                data.page_size = query?.page_size || 100
                                data.type = "enterprise"
                                const _result = await getListOrganizationAdmin(data)
                                try {
                                    if (_result.status === "success") {
                                        dispatch(setDefautlAdminOrg(_result?.data?.data[0]))
                                        saveDefaultAdminOrganization(_result?.data?.data[0])
                                        dispatch(setIsRefresh(new Date().getDay()))
                                    }
                                } catch (error) {
                                    throw error
                                }
                            }
                            getListAdminOrg()
                        }
                        window.location.reload()

                    }
                } else {
                    setLoading(false)
                    setShowModal({ show: true, msg: t("Login error") })
                }
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            throw error
        }
    }
    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: process.env.REACT_APP_CLIENT_ID_GOOGLE,
                scope: 'email',
            });
        }

        gapi.load('client:auth2', start);
    }, []);

    if (loading) {
        return <div className="btn-social">
            <Spin
                spinning={loading}
                indicator={<Loading3QuartersOutlined spin style={{ display: "block" }} />}
            />
        </div>
    }

    return (
        <GoogleLogin
            clientId={`${process.env.REACT_APP_CLIENT_ID_GOOGLE}`}
            render={renderProps => (
                <button onClick={renderProps.onClick} className='btn-social'>
                    <IconGG />
                </button>
            )}
            onSuccess={handleLoginGoogle}
            cookiePolicy={"single_host_origin"}
            prompt="select_account"
        />
    )
}

export default LoginWithGoogle
