import axios from "axios";
import { getUser, removeUserSession } from "../utils/common";

const ApiMicroService = axios.create({
    baseURL: process.env.REACT_APP_MICRO_SERVICE_URL,
    headers: {
        'Accept-Language': `${window.localStorage.getItem("lang")}`
    },
    responseType: 'json'
})

const userSession = getUser()

ApiMicroService.defaults.timeout = 200000
ApiMicroService.defaults.headers.post['Content-Type'] = 'application/json'
ApiMicroService.defaults.headers.common['Authorization'] = userSession ? 'Bearer ' + userSession['accessToken'] : ''

ApiMicroService.interceptors.response.use(
    function(response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if ((response.data && response.data.error_code === 401) || (response.data && response.data.code === 401) || (response.data && response.data.error_code === 403) || (response.data && response.data.error_code === 403)) {
            removeUserSession()
            window.location.reload()
        }
        return response
    },
    function(error) {

        if (error?.response?.status === 401 || error?.response?.data?.code === 401 || error?.response?.status === 403 || error?.response?.status === 403) {
            removeUserSession()
            window.location.reload()
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error)
    }
)


export default ApiMicroService
