import { type } from "os"
import { ACTIONS } from "../../constants/common"

export const setLoading = (isLoading: boolean) => ({
    type: ACTIONS.SET_IS_LOADING,
    payload: isLoading
})

export const setIsShowSidebar = (isSidebarShow: boolean) => ({
    type: ACTIONS.SET_SIDEBAR_SHOW,
    payload: isSidebarShow
})

export const setLoggedIn = (isLoggedIn: boolean) => ({
    type: ACTIONS.SET_IS_LOGGED_IN,
    payload: isLoggedIn
})

export const setDefautlAdminOrg = (info: any) => ({
    type: ACTIONS.SET_DEFAULT_ADMIN_ORG,
    payload: info
})
export const setEditing = (isEdit: boolean) => ({
    type: ACTIONS.SET_IS_EDITING,
    payload: isEdit
})

export const setIsRefresh = (isRefresh: any) => ({
    type: ACTIONS.SET_IS_REFRESH,
    payload: isRefresh
})

export const setIsShowModal = (isShowModal: any) => ({
    type: ACTIONS.SET_IS_SHOW_MODAL,
    payload: isShowModal
})

export const setIsTimeEnd = (isTimeEnd: any) => ({
    type: ACTIONS.SET_IS_TIME_END,
    payload: isTimeEnd
})

export const setIsCollapsed = (isCollapsed: any) => ({
    type: ACTIONS.SET_IS_COLLAPSED,
    payload: isCollapsed
})
