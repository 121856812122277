import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Input, InputNumber, Row, message, Dropdown, Space, Menu, Select, notification, Modal } from 'antd'
import { DownOutlined, LeftOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { ValidateForm } from '../../../interface/common'
import { saveUser } from '../../../utils/common'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getEmailPermissionsets, requestEmailLogin, requestPhoneLogin, getPhonePermissionsets } from '../../../api/user-api'
import imgLogin from '../../../assets/images/Login.png'
import { useTranslation } from 'react-i18next'
import IconVie from '../../../assets/icon/IconVie'
import IconEn from '../../../assets/icon/IconEn'
import { LogoLogin } from '../../../assets/icon/LogoLogin'
import 'react-phone-input-2/lib/style.css'
import CountDown from '../../common/CountDown'
import { setIsTimeEnd } from '../../../redux/actions/common-actions'

const LoginAdmin = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [languages, setLanguages] = useState<string>("en")
  const [countResend, setCountResend] = useState<number>(3)
  const [loading, setLoading] = useState<boolean>(false)
  const [verificationId, setVerificationId] = useState<number>()
  const [stepLogin, setStepLogin] = useState<{ type: string, verifying: boolean, verified: boolean }>({ type: "email", verifying: false, verified: false })
  const [validationEmail, setValidationEmail] = useState<ValidateForm>({ status: "success", msg: "" })
  const [validationCode, setValidationCode] = useState<ValidateForm>({ status: "success", msg: "" })
  const [showModal, setShowModal] = useState<{ show: boolean, msg: "" }>({ show: false, msg: "" })
  const isTimeEnd = useSelector((state: any) => state.commonReducer.isTimeEnd || false)
  const [countDown, setCountDown] = useState<number>(300)

  // send OTP
  const handleSendOTP = async () => {
    // setCounter(100)
    setLoading(true)
    try {
      // login with email
      if (stepLogin.type === "email") {
        let data = {
          email: form.getFieldValue("email")
        }
        if (form.getFieldValue("email")?.length < 1) {
          setLoading(false)
          setValidationEmail({ status: "error", msg: t("Please fill your email!") })
          setStepLogin({ type: "email", verifying: false, verified: false })
          return
        }
        const result = await requestEmailLogin(data)
        if (result.status === "success") {
          setLoading(false)
          setVerificationId(result?.data?.verification_id)
          setStepLogin({ type: "email", verifying: true, verified: false })
        } else {
          setLoading(false)
          setValidationEmail({ msg: t("Your email invalid!"), status: "error" })
        }
      }
    } catch (error) {
      setLoading(false)
      throw error
    }
  }

  // sign in
  const handleSignIn = async () => {
    setLoading(true)
    try {
      // verified email
      if (stepLogin.type === "email") {
        let data: any = {
          email: form.getFieldValue("email"),
          verification_id: verificationId,
          code: form.getFieldValue("code")
        }
        const result = await getEmailPermissionsets(data)
        if (result.status === "success") {
          setLoading(false)
          if (!result?.data?.role || result?.data?.role !== "admin") {
            setShowModal({ show: true, msg: t("Invalid user") })
          } else {
            saveUser({
              email: form.getFieldValue("email"),
              accessToken: result?.data.access_token,
              expires: result?.data.expires_in,
              role: result?.data?.role,
              organization: result?.data?.organization
            })
            window.location.reload()
            navigate('/')
          }
        }
        if (result.status === "error" && result.code === "VERIFICATION_USER_INACTIVE") {
          setLoading(false)
          setShowModal({ show: true, msg: t("Invalid user") })
          setValidationCode({ status: "success", msg: "" })
          setCountResend(3)
        }
        if (result.status === "error" && result.code !== "VERIFICATION_USER_INACTIVE") {
          setLoading(false)
          setCountResend((prev: any) => (prev - 1))
          setValidationCode({ status: "error", msg: t(`OTP code is invalid.`) + " " + t(`You have`) + " " + (countResend - 1) + " " + t(`more chances.`) })
          if (countResend === 1) {
            setShowModal({ show: true, msg: t("You have entered too many times. Please return to the login screen") })
            setValidationCode({ status: "success", msg: "" })
            setCountResend(3)
          }
        }
      }
    } catch (error) {
      setLoading(false)
      throw error
    }
  }

  const handleResendOTP = async () => {
    setCountDown(300)
    dispatch(setIsTimeEnd(false))
    try {
      // resend OTP
      let data = {
        email: form.getFieldValue("email")
      }
      const result = await requestEmailLogin(data)
      if (result.status === "success") {
        setLoading(false)
        setVerificationId(result?.data?.verification_id)
        setStepLogin({ type: "email", verifying: true, verified: false })
      } else {
        setLoading(false)
        setValidationEmail({ msg: t("Your email invalid!"), status: "error" })
      }
    } catch (error) {
      setLoading(false)
      throw error
    }
  }

  const changeLanguage = (
    <Menu style={{ width: 150 }}>
      <Menu.Item key="vi" onClick={() => handleChangeLanguage("vi")}>
        <Space>
          <IconVie />
          {t("Vietnamese")}
        </Space>
      </Menu.Item>
      <Menu.Item key="eng" onClick={() => handleChangeLanguage("en")}>
        <Space>
          <IconEn />
          {t("English")}
        </Space>
      </Menu.Item>
    </Menu>
  )

  const handleChangeLanguage = (language: string) => {
    setLanguages(language)
    i18n.changeLanguage(language)
    window.localStorage.setItem("lang", language)
  }


  useEffect(() => {
    // format form
    form.setFieldsValue({
      email: "",
      phone_code: "",
      phone: ""
    })
  }, [])

  return (
    <>
      <Form form={form}>
        <Row gutter={[40, 0]} className="login_section">
          <Col span={12}>
            <div className="login">
              <div className='login_header'>
                <LogoLogin />
              </div>

              <div className='form_login'>
                <div className="login_main">
                  {/* when not verifyng */}
                  {!stepLogin.verifying && (
                    <>
                      <p className="login_desc mb-0">
                        {t("Welcome back!")}
                      </p>
                      <p className='login_title mb-15'>
                        {t("Sign in")}
                      </p>

                      <p className='mb-5' style={{ fontWeight: 500 }}>{t("Email")}</p>

                      {stepLogin.type === "email" && (
                        <Form.Item
                          style={{ marginBottom: 16 }}
                          name="email"
                          help={validationEmail.msg}
                          validateStatus={validationEmail.status}
                          normalize={(value) => {
                            if (value) {
                              setValidationEmail({ status: "success", msg: "" })
                            }
                            return value
                          }}
                        >
                          <Input size="large" placeholder={t("name@company.com")} />
                        </Form.Item>
                      )}

                      <Button type="primary" loading={loading} className='button' style={{ width: "100%" }} htmlType="submit" onClick={handleSendOTP}>
                        {t("Send OTP")}
                      </Button>
                    </>
                  )}

                  {stepLogin.verifying && <>
                    <Button type="link" className="btn-back" onClick={() => {
                      form.setFieldValue("code", "")
                      setCountResend(3)
                      setValidationCode({ status: "success", msg: "" })
                      setStepLogin((prev: any) => ({ ...prev, verifying: false }))
                    }}>
                      <LeftOutlined style={{ fontSize: 13, fontWeight: 500 }} />
                      {t("Back")}
                    </Button>

                    {stepLogin.type === "email" && (
                      <p className='mb-5 semibold'>
                        {t("We have sent OTP to") + " " + form.getFieldValue("email")}
                      </p>
                    )}

                    <Form.Item
                      style={{ marginBottom: 16 }}
                      name="code"
                      help={validationCode.msg}
                      validateStatus={validationCode.status}
                      normalize={(value) => {
                        if (value) {
                          setValidationCode({ status: "success", msg: "" })
                        }
                        return value
                      }}
                    >
                      <Input style={{ height: 40 }} maxLength={6} suffix={
                        <>
                          {(!isTimeEnd) ? (
                            <Button disabled type="link" className="btn-resend">
                              {t("Resend after")}
                              &nbsp;
                              <CountDown seconds={countDown} />
                            </Button>
                          ) : (
                            <Button type="link" className="btn-resend" onClick={handleResendOTP}>{`${t("Resend")}`}</Button>
                          )}
                        </>
                      }
                      />
                    </Form.Item>
                    <Button type="primary" loading={loading} className='button' style={{ float: "right" }} htmlType="submit" onClick={handleSignIn}>
                      {t("Sign in")}
                    </Button>
                  </>}
                </div>
              </div>


            </div>
            <p className="footer_text">
              <span>{t("By continuing, you agree to QRnet’s")}</span>
              &nbsp;
              <a href={`https://qrnet.me/policy/term-and-condition?lang=${languages}`} target="_blank" className="term_policy">{t("Term of Service")}</a>
              &nbsp;
              <span>{t("and")}</span>
              <br />
              <a href={`https://qrnet.me/policy/privacy-policy?lang=${languages}`} target="_blank" className="term_policy">{t("Privacy Policy")}</a>
            </p>
          </Col>

          <Col span={12} className="p-0 login_right" >
            <Dropdown className="dropdownLanguage" overlay={changeLanguage} trigger={['click']} >
              <Space style={{ cursor: "pointer" }}>
                {/* <IconLanguage /> */}
                {window.localStorage.getItem("lang") === "vi" ? <IconVie /> : <IconEn />}
                <DownOutlined style={{ color: "white" }} />
              </Space>
            </Dropdown>
            <div className='login_image'>
              <img src={imgLogin} />
            </div>
          </Col>

        </Row>
      </Form >

      <Modal
        closable={false}
        open={showModal.show || countResend === 0}
        centered
        cancelButtonProps={{ style: { display: "none" } }}
        width={416}
        onOk={() => {
          setShowModal({ show: false, msg: "" })
          setStepLogin((prev: any) => ({ ...prev, verifying: false }))
          setValidationCode({ status: "success", msg: "" })
          form.setFieldValue("code", "")
        }}
        okText={
          <span>{t("Back to login")}</span>
        }
      >
        <div className='d-flex' style={{ alignItems: "center" }}>
          <CloseCircleOutlined className="mr-15" style={{ color: "#FF4D4F", fontSize: 22 }} />
          <span style={{ fontSize: 16, fontWeight: 500, color: "rgba(0, 0, 0, 0.85)" }}>{t(showModal.msg)}</span>
        </div>
      </Modal>
    </>
  )
}

export default LoginAdmin
